<template>
  <div>
    <b-tabs
      v-model="tabIndex"
      pills
      vertical
      no-nav-style
      nav-wrapper-class="pr-0">
      <b-tab
        title="Contact Center Monthly Report (RPT006)"
        :title-link-class="linkClass(0)"
        active>
        <b-card-text>
          <b-card class="rounded-0 shadow-none">
            <ContactCenterMonthlyReport />
          </b-card>
        </b-card-text>
      </b-tab>
      <b-tab
        title="Complaint Monthly Report (RPT011)"
        :title-link-class="linkClass(1)">
        <b-card-text>
          <b-card class="rounded-0 shadow-none">
            <ComplaintMonthlyReport />
          </b-card>
        </b-card-text>
      </b-tab>
      <b-tab
        title="Voice of customers Report (RPT012)"
        :title-link-class="linkClass(2)">
        <b-card-text>
          <b-card class="rounded-0 shadow-none">
            <VoiceOfCustomerReport />
          </b-card>
        </b-card-text>
      </b-tab>
      <b-tab
        title="Do not call list Report (RPT013)"
        :title-link-class="linkClass(3)">
        <b-card-text>
          <b-card class="rounded-0 shadow-none">
            <DoNotCallListReport />
          </b-card>
        </b-card-text>
      </b-tab>
      <b-tab
        title="Call Survey Report (RPT014)"
        :title-link-class="linkClass(4)">
        <b-card-text>
          <b-card class="rounded-0 shadow-none">
            <CallSurveyReport />
          </b-card>
        </b-card-text>
      </b-tab>
      <b-tab
        title="NPS Survey Report (RPT015)"
        :title-link-class="linkClass(5)">
        <b-card-text>
          <b-card class="rounded-0 shadow-none">
            <NpsSurveyReport />
          </b-card>
        </b-card-text>
      </b-tab>
      <b-tab
        title="รายงานคดี (กฎหมาย)"
        :title-link-class="linkClass(6)">
        <b-card-text>
          <b-card class="rounded-0 shadow-none">
            <CaseReport />
          </b-card>
        </b-card-text>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import ContactCenterMonthlyReport from './ContactCenterMonthlyReport.vue'
import ComplaintMonthlyReport from './ComplaintMonthlyReport.vue'
import VoiceOfCustomerReport from './VoiceOfCustomerReport.vue'
import DoNotCallListReport from './DoNotCallListReport.vue'
import CallSurveyReport from './CallSurveyReport.vue'
import NpsSurveyReport from './NpsSurveyReport.vue'
import CaseReport from './CaseReport.vue'

export default {
  components: {
    ContactCenterMonthlyReport,
    ComplaintMonthlyReport,
    VoiceOfCustomerReport,
    DoNotCallListReport,
    CallSurveyReport,
    NpsSurveyReport,
    CaseReport
  },
  data () {
    return {
      tabIndex: 0
    }
  },
  methods: {
    linkClass (idx) {
      if (this.tabIndex === idx) {
        return ['bg-white', 'text-dark']
      }
      return ['bg-gray', 'text-dark']
    }
  }
}
</script>

<style lang="scss" scoped>
div ::v-deep .nav-link {
  padding: 1.1rem 0.5rem;
  border-radius: 8px 0px 0px 8px;
}

div ::v-deep .tab-content {
  padding-left: 0px;
}

div ::v-deep .nav.flex-column {
  width: 236px;
  margin-left: -4px;
}

@media (min-width: 600px) {
  .nav {
    .flex-column {
      width: 600px;
    }
  }
}
</style>
