<template>
  <div>
    <div class="d-flex justify-content-between pb-3">
      <div class="d-flex">
        <ButtonGroupFilter
          :tab.sync="tab"
          class="mr-2"
        />
        <DatePickerFilterRange
          v-if="tab === 0" />
        <DatePickerFilterMonth
          v-if="tab === 1" />
        <DatePickerFilterYear
          v-if="tab === 2" />
        <DatePickerFilterQuarter
          v-if="tab === 3" />
      </div>
    </div>
    <div
      v-if="tab === 0"
      class="pt-3 mb-5">
      <ExportFile
        title="Do not call list Report (RPT013)" />
    </div>
    <div
      v-if="tab === 1"
      class="pt-3 mb-5">
      <ExportFile
        title="Do not call list Report (RPT013)" />
    </div>
    <div
      v-if="tab === 2"
      class="pt-3 mb-5">
      <ExportFile
        title="Do not call list Report (RPT013)" />
    </div>
    <div
      v-if="tab === 3"
      class="pt-3 mb-5">
      <ExportFile
        title="Do not call list Report (RPT013)" />
    </div>
  </div>
</template>

<script>
import ButtonGroupFilter from '@/components/ButtonGroupFilter.vue'
import DatePickerFilterRange from '@/components/DatePickerFilterRange.vue'
import DatePickerFilterMonth from '@/components/DatePickerFilterMonth.vue'
import DatePickerFilterYear from '@/components/DatePickerFilterYear.vue'
import DatePickerFilterQuarter from '@/components/DatePickerFilterQuarter.vue'
import ExportFile from '@/components/ExportFile.vue'

export default {
  components: {
    ButtonGroupFilter,
    DatePickerFilterMonth,
    DatePickerFilterRange,
    DatePickerFilterYear,
    DatePickerFilterQuarter,
    ExportFile
  },
  data () {
    return {
      tab: 0
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
