<template>
  <div>
    <date-picker
      v-model="innerValue"
      type="date"
      range
      placeholder="Select date range">
    </date-Picker>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  components: {
    DatePicker
  },
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    innerValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

div ::v-deep .mx-input {
  border-radius: 20px;
  border-color: #00A8D0;
  height: 40px;
}

::v-deep .mx-icon-calendar {
  color: #00A8D0;
}

</style>
