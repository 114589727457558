<template>
  <div>
    <div class="border--quarter">
      <VueDatePicker
        v-model="date"
        :min-date="minDate"
        :max-date="maxDate"
        type="quarter"
        placeholder="Choose date"
        @input="selectQuarter(formatQuarterToDate)"
      >
        <template #input-icon>
          <b-icon
            style="position: absolute; top: 7px; right: 10px;"
            icon="calendar4"
            variant="primary"></b-icon>
        </template>
      </VueDatePicker>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'

const date = new Date()

export default {
  data: () => ({
    date: `${date.getFullYear()}-3`,
    minDate: `${date.getFullYear() - 3}`,
    maxDate: `${date.getFullYear()}-3`
  }),
  computed: {
    formatQuarterToDate () {
      const dateData = this.date
      const splitDate = dateData.split('-')
      if (Array.isArray(splitDate) && splitDate.length >= 2) {
        const year = +splitDate[0]
        const quarter = +splitDate[1]
        const transformQuarterToDate = (years, month, dates) => `${years}-${month}-${dates}`
        let startDate
        let endDate
        switch (quarter) {
        case 1:
          startDate = transformQuarterToDate(year, 1, 1)
          endDate = transformQuarterToDate(year, 3, 31)
          break
        case 2:
          startDate = transformQuarterToDate(year, 4, 1)
          endDate = transformQuarterToDate(year, 6, 30)
          break
        case 3:
          startDate = transformQuarterToDate(year, 7, 1)
          endDate = transformQuarterToDate(year, 9, 30)
          break
        case 4:
          startDate = transformQuarterToDate(year, 10, 1)
          endDate = transformQuarterToDate(year, 12, 31)
          break
        default:
          break
        }
        return [dayjs(startDate).format('YYYY-MM-DD'), dayjs(endDate).format('YYYY-MM-DD')]
      }
      return null
    }
  },
  methods: {
    selectQuarter (val) {
      this.$emit('input', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.border--quarter {
  border: 1px solid #00A8D0;
  border-radius: 20px;
  height:40px;
  padding-top: 4px;
}

</style>
