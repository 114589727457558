<template>
  <div>
    <b-row>
      <b-col
        sm="12"
        md="2">
        <div class="pt-0">
          <h3 v-if="tabIndex === 0">
            Dashboards Topics
          </h3>
          <h3 v-if="tabIndex === 1">
            Reports Topics
          </h3>
          <h3 v-if="tabIndex === 2">
            Download
          </h3>
          <hr />
        </div>
      </b-col>
      <b-col
        sm="12"
        md="10">
        <b-tabs
          v-model="tabIndex"
          pills
        >
          <b-tab
            v-for="(header, index) in tabHeader"
            :key="'tab-' + index"
            :title-link-class="tabIndex === index ? 'rounded-tab text-white bg-primary' : 'rounded-tab text-primary bg-white'"
            :title="header"
          >
            <template #title>
              <div
                class="d-flex align-items-center justify-content-between">
                <span>
                  {{ header }}
                </span>
              </div>
            </template>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
    <div style="margin-top: -14px;">
      <DashboardGraph
        v-if="tabIndex === 0"
      />
      <Report
        v-if="tabIndex === 1" />
      <Download
        v-if="tabIndex === 2" />
    </div>
  </div>
</template>

<script>
import DashboardGraph from './Components/DashboardGraph.vue'
import Report from './Components/Report.vue'
import Download from './Components/Download.vue'

export default {
  components: {
    DashboardGraph,
    Report,
    Download
  },
  data () {
    return {
      tabIndex: 0,
      isActive: false,
      tabHeader: [
        'Dashboard',
        'Report',
        'Download'
      ]
    }
  }
}
</script>

<style lang="scss" scoped>

div ::v-deep .tab-content {
  padding-left: 0px;
}

hr {
  margin-top: 12px;
  margin-bottom: 1px;
  border: 0;
  border-top: 2px solid rgba(0, 0, 0, 0.421);
}

div ::v-deep .nav-pills .nav-link {
  width: 240px;
  height: 40px;
}
div ::v-deep .tabs .row {
  margin-top: -11px;
}

div ::v-deep .rounded-tab {
  border: 1px solid #44aad1 !important;
}

</style>
