<template>
  <div>
    <div class="d-flex justify-content-between pb-3">
      <div class="d-flex">
        <ButtonGroupFilter
          :tab.sync="tab"
          class="mr-2" />
        <DatePickerFilterRange
          v-if="tab === 0"
          v-model="dateCustom"
          @input="getIndividualCreateStatusProportionByCustom($event)" />
        <DatePickerFilterMonth
          v-if="tab === 1"
          v-model="dateMonth"
          @input="getIndividualCreateStatusProportionByMonth($event)" />
        <DatePickerFilterYear
          v-if="tab === 2"
          v-model="dateYear"
          @input="getIndividualCreateStatusProportionByYear($event)" />
        <DatePickerFilterQuarter
          v-if="tab === 3"
          v-model="dateQuarter"
          @input="getIndividualCreateStatusProportionByQuarter($event)" />
      </div>
    </div>
    <div
      v-show="tab === 0"
      class="d-flex justify-content-center">
      <canvas
        ref="chartCanvas"
        width="1000"
        height="539">
      </canvas>
    </div>
    <div v-if="tab === 1">
    </div>
    <div v-if="tab === 2">
    </div>
    <div v-if="tab === 3">
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js'
import ButtonGroupFilter from '@/components/ButtonGroupFilter.vue'
import DatePickerFilterRange from '@/components/DatePickerFilterRange.vue'
import DatePickerFilterMonth from '@/components/DatePickerFilterMonth.vue'
import DatePickerFilterYear from '@/components/DatePickerFilterYear.vue'
import DatePickerFilterQuarter from '@/components/DatePickerFilterQuarter.vue'
import IndividualCreateStatusProportionsProvider from '@/resources/IndividualCreationStatusProportions'
import dayjs from 'dayjs'

const IndividualCreateStatusProportionService = new IndividualCreateStatusProportionsProvider()

export default {
  components: {
    ButtonGroupFilter,
    DatePickerFilterRange,
    DatePickerFilterMonth,
    DatePickerFilterYear,
    DatePickerFilterQuarter
  },
  data () {
    return {
      tab: 0,
      dataOption: {
        labels: [],
        datasets: [{
          label: 'In Progress',
          backgroundColor: '#ED6E85',
          data: []
        }, {
          label: 'Closed',
          backgroundColor: '#6DBEBF',
          data: []
        }, {
          label: 'Cancelled',
          backgroundColor: '#569FE5',
          data: []
        }]
      },
      dateCustom: [],
      dateMonth: null,
      dateYear: null,
      dateQuarter: [],
      startDateFilter: null,
      endDateFilter: null
    }
  },
  created () {
    this.getIndividualCreateStatusProportionByCustom(this.dateCustom)
  },
  methods: {
    renderChart () {
      const ctx = this.$refs.chartCanvas.getContext('2d')
      const barChart = new Chart(ctx, {
        type: 'bar',
        data: this.dataOption,
        options: {
          responsive: false,
          scales: {
            yAxes: [{
              stacked: true,
              ticks: {
                beginAtZero: true
              }
            }],
            xAxes: [{
              stacked: true,
              ticks: {
                beginAtZero: true
              }
            }]
          }
        }
      })
      console.log(barChart)
    },
    // async getIndividualCreateStatusProportion () {
    //   if (this.tab === 0) {
    //     this.startDateFilter = dayjs(this.dateCustom[0]).format('YYYY-MM-DD')
    //     this.endDateFilter = dayjs(this.dateCustom[1]).format('YYYY-MM-DD')
    //   } else if (this.tab === 1) {
    //     this.startDateFilter = dayjs(this.dateMonth).startOf('M').format('YYYY-MM-DD')
    //     this.endDateFilter = dayjs(this.dateMonth).endOf('M').format('YYYY-MM-DD')
    //   } else {
    //     this.startDateFilter = dayjs(this.dateYear).startOf('y').format('YYYY-MM-DD')
    //     this.endDateFilter = dayjs(this.dateYear).endOf('y').format('YYYY-MM-DD')
    //   }
    //   const startDate = this.startDateFilter
    //   const endDate = this.endDateFilter
    //   const query = { startDate, endDate }
    //   const data = await IndividualCreateStatusProportionService.findAll(query)
    //   for (let i = 0; i < data.data.length; i++) {
    //     this.dataOption.labels[i] = data.data[i].username
    //     this.dataOption.datasets[0].data[i] = data.data[i].inProgress
    //     this.dataOption.datasets[1].data[i] = data.data[i].closed
    //     this.dataOption.datasets[2].data[i] = data.data[i].void
    //   }
    //   this.renderChart()
    // },
    async getIndividualCreateStatusProportionByCustom (custom) {
      const startDate = dayjs(custom[0]).format('YYYY-MM-DD')
      const endDate = dayjs(custom[1]).format('YYYY-MM-DD')
      const query = { startDate, endDate }
      const data = await IndividualCreateStatusProportionService.findAll(query)
      for (let i = 0; i < data.data.length; i++) {
        this.dataOption.labels[i] = data.data[i].username
        this.dataOption.datasets[0].data[i] = data.data[i].inProgress
        this.dataOption.datasets[1].data[i] = data.data[i].closed
        this.dataOption.datasets[2].data[i] = data.data[i].void
      }
      this.renderChart()
    },
    async getIndividualCreateStatusProportionByMonth (month) {
      const startDate = dayjs(month).startOf('M').format('YYYY-MM-DD')
      const endDate = dayjs(month).endOf('M').format('YYYY-MM-DD')
      const query = { startDate, endDate }
      const data = await IndividualCreateStatusProportionService.findAll(query)
      for (let i = 0; i < data.data.length; i++) {
        this.dataOption.labels[i] = data.data[i].username
        this.dataOption.datasets[0].data[i] = data.data[i].inProgress
        this.dataOption.datasets[1].data[i] = data.data[i].closed
        this.dataOption.datasets[2].data[i] = data.data[i].void
      }
      this.renderChart()
    },
    async getIndividualCreateStatusProportionByYear (year) {
      const startDate = dayjs(year).startOf('y').format('YYYY-MM-DD')
      const endDate = dayjs(year).endOf('y').format('YYYY-MM-DD')
      const query = { startDate, endDate }
      const data = await IndividualCreateStatusProportionService.findAll(query)
      for (let i = 0; i < data.data.length; i++) {
        this.dataOption.labels[i] = data.data[i].username
        this.dataOption.datasets[0].data[i] = data.data[i].inProgress
        this.dataOption.datasets[1].data[i] = data.data[i].closed
        this.dataOption.datasets[2].data[i] = data.data[i].void
      }
      this.renderChart()
    },
    async getIndividualCreateStatusProportionByQuarter (quarter) {
      if (Array.isArray(quarter) && quarter.length >= 2) {
        const startDate = quarter[0]
        const endDate = quarter[1]
        const query = { startDate, endDate }
        const data = await IndividualCreateStatusProportionService.findAll(query)
        for (let i = 0; i < data.data.length; i++) {
          this.dataOption.labels[i] = data.data[i].username
          this.dataOption.datasets[0].data[i] = data.data[i].inProgress
          this.dataOption.datasets[1].data[i] = data.data[i].closed
          this.dataOption.datasets[2].data[i] = data.data[i].void
        }
        this.renderChart()
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
