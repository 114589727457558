<template>
  <div>
    <date-picker
      v-model="innerValue"
      type="month"
      style="border: 1px solid #00A8D0 !important;
  border-radius: 20px !important;"
      placeholder="Select month">
    </date-Picker>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  components: {
    DatePicker
  },
  props: {
    value: {
      type: Date,
      required: false
    }
  },
  computed: {
    innerValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

div ::v-deep .mx-input {
  border-radius: 20px;
  border-color: #00A8D0;
  height: 40px;
}

::v-deep .mx-icon-calendar {
  color: #00A8D0;
}

// div ::v-deep .mx-datepicker-main {
//   border: 1px solid #00A8D0 !important;
//   border-radius: 20px !important;
// }

</style>
