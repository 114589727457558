<template>
  <div>
    <b-tabs
      v-model="tabIndex"
      pills
      vertical
      no-nav-style
      nav-wrapper-class="pr-0">
      <b-tab
        title="สถานะการสร้างเคส"
        :title-link-class="linkClass(0)"
        active>
        <b-card-text>
          <b-card class="rounded-0 shadow-none">
            <CaseCreateStatusDashboard />
          </b-card>
        </b-card-text>
      </b-tab>
      <b-tab
        title="การสร้างเคสรายบุคคล"
        :title-link-class="linkClass(1)">
        <b-card-text>
          <b-card class="rounded-0 shadow-none">
            <IndividualCaseCreateDashboard />
          </b-card>
        </b-card-text>
      </b-tab>
      <b-tab
        title="ช่องทางการติดต่อ"
        :title-link-class="linkClass(2)">
        <b-card-text>
          <b-card class="rounded-0 shadow-none">
            <ContactChannelDashboard />
          </b-card>
        </b-card-text>
      </b-tab>
      <b-tab
        title="ประเภทผู้ติดต่อ (Case Subject)"
        :title-link-class="linkClass(3)">
        <b-card-text>
          <b-card class="rounded-0 shadow-none">
            <ContactTypeDashboard />
          </b-card>
        </b-card-text>
      </b-tab>
      <b-tab
        title="ประเภทเรื่องที่ติดต่อ"
        :title-link-class="linkClass(4)">
        <b-card-text>
          <b-card class="rounded-0 shadow-none">
            <TypeOfContactDashboard />
          </b-card>
        </b-card-text>
      </b-tab>
      <b-tab
        title="เรื่องร้องเรียนแยกตามช่องทาง"
        :title-link-class="linkClass(5)">
        <b-card-text>
          <b-card class="rounded-0 shadow-none">
            <ComplaintsByChannelDashboard />
          </b-card>
        </b-card-text>
      </b-tab>
      <b-tab
        title="การดําเนินงานเรื่องร้องเรียน"
        :title-link-class="linkClass(6)">
        <b-card-text>
          <b-card class="rounded-0 shadow-none">
            <ComplaintHandlingDashboard />
          </b-card>
        </b-card-text>
      </b-tab>
      <b-tab
        title="การยุติเรื่องร้องเรียน"
        :title-link-class="linkClass(7)">
        <b-card-text>
          <b-card class="rounded-0 shadow-none">
            <SettlementOfComplaintsDashboard />
          </b-card>
        </b-card-text>
      </b-tab>
      <b-tab
        title="ระยะเวลาการดําเนินงาน เรื่องร้องเรียน (SLA)"
        :title-link-class="linkClass(8)">
        <b-card-text>
          <b-card class="rounded-0 shadow-none">
            <SlaDashboard />
          </b-card>
        </b-card-text>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import IndividualCaseCreateDashboard from './IndividualCaseCreateDashboard.vue'
import ContactChannelDashboard from './ContactChannelDashboard.vue'
import CaseCreateStatusDashboard from './CaseCreateStatusDashboard.vue'
import ContactTypeDashboard from './ContactTypeDashboard.vue'
import TypeOfContactDashboard from './TypeOfContactDashboard.vue'
import ComplaintsByChannelDashboard from './ComplaintsByChannelDashboard.vue'
import ComplaintHandlingDashboard from './ComplaintHandlingDashboard.vue'
import SettlementOfComplaintsDashboard from './SettlementOfComplaintsDashboard.vue'
import SlaDashboard from './SlaDashboard.vue'

export default {
  components: {
    IndividualCaseCreateDashboard,
    ContactChannelDashboard,
    CaseCreateStatusDashboard,
    ContactTypeDashboard,
    TypeOfContactDashboard,
    ComplaintsByChannelDashboard,
    ComplaintHandlingDashboard,
    SettlementOfComplaintsDashboard,
    SlaDashboard
  },
  data () {
    return {
      tabIndex: 0
    }
  },
  methods: {
    linkClass (idx) {
      if (this.tabIndex === idx) {
        return ['bg-white', 'text-dark']
      }
      return ['bg-gray', 'text-dark']
    }
  }
}
</script>

<style lang="scss" scoped>
div ::v-deep .nav-link {
  padding: 1.2rem 0.5rem;
  border-radius: 8px 0px 0px 8px;
}

div ::v-deep .tab-content {
  padding-left: 0px;
}

div ::v-deep .nav.flex-column {
  width: 236px;
  margin-left: -4px;
}
</style>
