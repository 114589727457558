<template>
  <div>
    <div class="d-flex justify-content-center">
      <b-button
        variant="outline-primary px-0 mr-3">
        <b-img
          class="p-1"
          width="210"
          src="@/assets/images/icons/iwwa_file-csv.svg"
          alt="file-csv"
        ></b-img>
      </b-button>
      <b-button
        variant="outline-secondary px-0">
        <b-img
          class="p-1"
          width="210"
          src="@/assets/images/icons/file-xlsx.svg"
          alt="file-xlsx"
        ></b-img>
      </b-button>
    </div>
    <div class="text-center py-2">
      <h2>{{ title }}</h2>
    </div>
    <div class="d-flex justify-content-center">
      <b-button
        style="width: 42%;"
        variant="success">
        Export
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.border--thin {
  border-style: solid;
  border-width: thin;
}
</style>
