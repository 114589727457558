<template>
  <div>
    <div class="d-flex justify-content-between pb-3">
      <div class="d-flex">
        <ButtonGroupFilter
          :tab.sync="tab"
          class="mr-2"
        />
        <DatePickerFilterRange
          v-if="tab === 0"
          v-model="dateCustom"
          @input="getOrganizesTimelineOfComplianceByCustom($event)" />
        <DatePickerFilterMonth
          v-if="tab === 1"
          v-model="dateMonth"
          @input="getOrganizesTimelineOfComplianceByMonth($event)" />
        <DatePickerFilterYear
          v-if="tab === 2"
          v-model="dateYear"
          @input="getOrganizesTimelineOfComplianceByYear($event)" />
        <DatePickerFilterQuarter
          v-if="tab === 3"
          v-model="dateQuarter"
          @input="getOrganizesTimelineOfComplianceByQuarter($event)" />
      </div>
    </div>
    <div
      v-show="tab === 0"
      class="d-flex justify-content-center"
    >
      <canvas
        ref="chartCanvas"
        width="420"
        height="420">
      </canvas>
    </div>
    <div v-if="tab === 1">
    </div>
    <div v-if="tab === 2">
    </div>
    <div v-if="tab === 3">
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js'
import ButtonGroupFilter from '@/components/ButtonGroupFilter.vue'
import DatePickerFilterRange from '@/components/DatePickerFilterRange.vue'
import DatePickerFilterMonth from '@/components/DatePickerFilterMonth.vue'
import DatePickerFilterYear from '@/components/DatePickerFilterYear.vue'
import DatePickerFilterQuarter from '@/components/DatePickerFilterQuarter.vue'
import OrganizesTimelineOfComplianceProvider from '@/resources/OrganizesTimelineOfComplianceProvider'
import dayjs from 'dayjs'

const OrganizesTimelineOfComplianceService = new OrganizesTimelineOfComplianceProvider()

export default {
  components: {
    ButtonGroupFilter,
    DatePickerFilterMonth,
    DatePickerFilterRange,
    DatePickerFilterYear,
    DatePickerFilterQuarter
  },
  data () {
    return {
      tab: 0,
      value: '',
      formatted: '',
      selected: '',
      dataIntegrate: [],
      labelsIntegrate: [
        '1-3 วัน',
        '4-7 วัน (1 สัปดาห์)',
        '8-14 วัน (2 สัปดาห์)',
        '15-21 วัน (3 สัปดาห์)',
        '22-30 วัน (4 สัปดาห์)',
        '30 วันขึ้นไป'
      ],
      backgroundColorRandom: [
        '#569FE5',
        '#6DBEBF',
        '#F7CF6B',
        '#EC6E85',
        '#E89643',
        '#E55656'
      ],
      dateCustom: [],
      dateMonth: null,
      dateYear: null,
      dateQuarter: [],
      startDateFilter: null,
      endDateFilter: null
    }
  },
  created () {
    this.getOrganizesTimelineOfComplianceByCustom(this.dateCustom)
  },
  methods: {
    renderChart () {
      const ctx = this.$refs.chartCanvas.getContext('2d')
      const dataOption = this.dataIntegrate
      const total = dataOption.reduce((sum, value) => sum + value, 0)
      const percentages = dataOption.map((value) => ((value / total) * 100).toFixed(0))
      const labelsOption = this.labelsIntegrate
      const pieChart = new Chart(ctx, {
        type: 'pie',
        data: {
          labels: labelsOption,
          datasets: [{
            label: 'My First Dataset',
            data: dataOption,
            backgroundColor: this.backgroundColorRandom,
            hoverOffset: 4
          }]
        },
        options: {
          responsive: false,
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                const dataset = data.datasets[tooltipItem.datasetIndex]
                const currentValue = dataset.data[tooltipItem.index]
                const percentage = percentages[tooltipItem.index]
                const label = labelsOption[tooltipItem.index]
                return `${label}: ${currentValue} (${percentage}%)`
              }
            }
          }
        }
      })
      console.log(pieChart)
    },
    onContext (val) {
      this.formatted = val.selectedFormatted
      this.selected = val.selectedYMD
    },
    async getOrganizesTimelineOfCompliance () {
      try {
        if (this.tab === 0) {
          this.startDateFilter = dayjs(this.dateCustom[0]).format('YYYY-MM-DD')
          this.endDateFilter = dayjs(this.dateCustom[1]).format('YYYY-MM-DD')
        } else if (this.tab === 1) {
          this.startDateFilter = dayjs(this.dateMonth).startOf('M').format('YYYY-MM-DD')
          this.endDateFilter = dayjs(this.dateMonth).endOf('M').format('YYYY-MM-DD')
        } else {
          this.startDateFilter = dayjs(this.dateYear).startOf('y').format('YYYY-MM-DD')
          this.endDateFilter = dayjs(this.dateYear).endOf('y').format('YYYY-MM-DD')
        }
        const startDate = this.startDateFilter
        const endDate = this.endDateFilter
        const query = { startDate, endDate }
        const data = await OrganizesTimelineOfComplianceService.findAll(query)
        this.dataIntegrate[0] = data.data.dayThreeData
        this.dataIntegrate[1] = data.data.weekOneData
        this.dataIntegrate[2] = data.data.weekTwoData
        this.dataIntegrate[3] = data.data.weekThreeData
        this.dataIntegrate[4] = data.data.weekFourData
        this.dataIntegrate[5] = data.data.otherData
      } catch (error) {
        console.log(error)
      }
      this.renderChart()
    },
    async getOrganizesTimelineOfComplianceByCustom (custom) {
      try {
        const startDate = dayjs(custom[0]).format('YYYY-MM-DD')
        const endDate = dayjs(custom[1]).format('YYYY-MM-DD')
        const query = { startDate, endDate }
        const data = await OrganizesTimelineOfComplianceService.findAll(query)
        this.dataIntegrate[0] = data.data.dayThreeData
        this.dataIntegrate[1] = data.data.weekOneData
        this.dataIntegrate[2] = data.data.weekTwoData
        this.dataIntegrate[3] = data.data.weekThreeData
        this.dataIntegrate[4] = data.data.weekFourData
        this.dataIntegrate[5] = data.data.otherData
      } catch (error) {
        console.log(error)
      }
      this.renderChart()
    },
    async getOrganizesTimelineOfComplianceByMonth (month) {
      try {
        const startDate = dayjs(month).startOf('M').format('YYYY-MM-DD')
        const endDate = dayjs(month).endOf('M').format('YYYY-MM-DD')
        const query = { startDate, endDate }
        const data = await OrganizesTimelineOfComplianceService.findAll(query)
        this.dataIntegrate[0] = data.data.dayThreeData
        this.dataIntegrate[1] = data.data.weekOneData
        this.dataIntegrate[2] = data.data.weekTwoData
        this.dataIntegrate[3] = data.data.weekThreeData
        this.dataIntegrate[4] = data.data.weekFourData
        this.dataIntegrate[5] = data.data.otherData
      } catch (error) {
        console.log(error)
      }
      this.renderChart()
    },
    async getOrganizesTimelineOfComplianceByYear (year) {
      try {
        const startDate = dayjs(year).startOf('y').format('YYYY-MM-DD')
        const endDate = dayjs(year).endOf('y').format('YYYY-MM-DD')
        const query = { startDate, endDate }
        const data = await OrganizesTimelineOfComplianceService.findAll(query)
        this.dataIntegrate[0] = data.data.dayThreeData
        this.dataIntegrate[1] = data.data.weekOneData
        this.dataIntegrate[2] = data.data.weekTwoData
        this.dataIntegrate[3] = data.data.weekThreeData
        this.dataIntegrate[4] = data.data.weekFourData
        this.dataIntegrate[5] = data.data.otherData
      } catch (error) {
        console.log(error)
      }
      this.renderChart()
    },
    async getOrganizesTimelineOfComplianceByQuarter (quarter) {
      try {
        if (Array.isArray(quarter) && quarter.length >= 2) {
          const startDate = quarter[0]
          const endDate = quarter[1]
          const query = { startDate, endDate }
          const data = await OrganizesTimelineOfComplianceService.findAll(query)
          this.dataIntegrate[0] = data.data.dayThreeData
          this.dataIntegrate[1] = data.data.weekOneData
          this.dataIntegrate[2] = data.data.weekTwoData
          this.dataIntegrate[3] = data.data.weekThreeData
          this.dataIntegrate[4] = data.data.weekFourData
          this.dataIntegrate[5] = data.data.otherData
        }
      } catch (error) {
        console.log(error)
      }
      this.renderChart()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
