<template>
  <div>
    <div class="d-flex justify-content-between pb-3">
      <div class="d-flex">
        <ButtonGroupFilter
          :tab.sync="tab"
          class="mr-2" />
        <DatePickerFilterRange
          v-if="tab === 0"
          v-model="dateCustom"
          @input="getProportionContactChannelsChennelByCustom($event)"
        />
        <DatePickerFilterMonth
          v-if="tab === 1"
          v-model="dateMonth"
          @input="getProportionContactChannelsChennelByMonth($event)" />
        <DatePickerFilterYear
          v-if="tab === 2"
          v-model="dateYear"
          @input="getProportionContactChannelsChennelByYear($event)" />
        <DatePickerFilterQuarter
          v-if="tab === 3"
          v-model="dateQuarter"
          @input="getProportionContactChannelsChennelByQuarter($event)" />
      </div>
    </div>
    <div
      v-show="tab === 0"
      class="d-flex justify-content-center">
      <canvas
        ref="chartCanvas"
        width="1000"
        height="539">
      </canvas>
    </div>
    <div v-if="tab === 1">
    </div>
    <div v-if="tab === 2">
    </div>
    <div v-if="tab === 3">
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js'
import ButtonGroupFilter from '@/components/ButtonGroupFilter.vue'
import DatePickerFilterRange from '@/components/DatePickerFilterRange.vue'
import DatePickerFilterMonth from '@/components/DatePickerFilterMonth.vue'
import DatePickerFilterYear from '@/components/DatePickerFilterYear.vue'
import DatePickerFilterQuarter from '@/components/DatePickerFilterQuarter.vue'
import ProportionContactChannelsChennelProvider from '@/resources/ProportionContactChannelsChannel'
import dayjs from 'dayjs'

const ProportionContactChannelsChennelService = new ProportionContactChannelsChennelProvider()

export default {
  components: {
    ButtonGroupFilter,
    DatePickerFilterRange,
    DatePickerFilterMonth,
    DatePickerFilterYear,
    DatePickerFilterQuarter
  },
  data () {
    return {
      tab: 0,
      dataIntegrate: [],
      labelsIntrgrate: [],
      dateCustom: [],
      dateMonth: null,
      dateYear: null,
      dateQuarter: [],
      startDateFilter: null,
      endDateFilter: null
    }
  },
  created () {
    this.getProportionContactChannelsChennelByCustom(this.dateCustom)
    // this.renderChart()
  },
  methods: {
    renderChart () {
      const ctx = this.$refs.chartCanvas.getContext('2d')
      const dataOption = this.dataIntegrate
      const total = dataOption.reduce((sum, value) => sum + value, 0)
      const percentages = dataOption.map((value) => ((value / total) * 100).toFixed(0))
      const labelsOption = this.labelsIntrgrate

      const mixedChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: labelsOption,
          datasets: [
            {
              label: 'Percent',
              yAxisID: 'percent',
              data: percentages,
              type: 'line',
              borderColor: '#ED6E85',
              fill: false,
              pointRadius: 8,
              lineTension: 0,
              borderJoinStyle: 'bevel'
            },
            {
              label: 'Total',
              yAxisID: 'amount',
              data: dataOption,
              type: 'bar',
              backgroundColor: '#569FE5'
            }
          ]
        },
        options: {
          responsive: false,
          scales: {
            yAxes: [{
              id: 'amount',
              type: 'linear',
              position: 'left'
            }, {
              id: 'percent',
              type: 'linear',
              position: 'right',
              ticks: {
                callback: (value, index, values) => `${value}%`
              }
            }],
            y: {
              beginAtZero: true,
              ticks: {
                callback: (value) => {
                  if (mixedChart._metasets[0].type === 'line') {
                    return `${value }%` // Display percentage for line chart
                  }
                  return value // Display amount for bar chart
                }
              }
            }
          },
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                const { datasetIndex } = tooltipItem
                // const datasetLabel = data.datasets[datasetIndex].label
                const { value } = tooltipItem
                if (data.datasets[datasetIndex].type === 'line') {
                  return `${ value }%` // Display percentage for line chart
                }
                return `${ value}` // Display amount for bar chart
              }
            }
          }
        }
      })
      console.log(mixedChart)
    },
    async getProportionContactChannelsChennelByCustom (custom) {
      if (Array.isArray(custom) && custom.length >= 2) {
        const startDate = custom[0]
        const endDate = custom[1]
        const query = { startDate, endDate }
        const data = await ProportionContactChannelsChennelService.findAll(query)
        for (let i = 0; i < data.data.length; i++) {
          this.labelsIntrgrate[i] = data.data[i].name
          this.dataIntegrate[i] = data.data[i].total
        }
        this.renderChart()
      }
    },
    async getProportionContactChannelsChennelByMonth (month) {
      const startDate = dayjs(month).startOf('M').format('YYYY-MM-DD')
      const endDate = dayjs(month).endOf('M').format('YYYY-MM-DD')
      const query = { startDate, endDate }
      const data = await ProportionContactChannelsChennelService.findAll(query)
      for (let i = 0; i < data.data.length; i++) {
        this.labelsIntrgrate[i] = data.data[i].name
        this.dataIntegrate[i] = data.data[i].total
      }
      this.renderChart()
    },
    async getProportionContactChannelsChennelByYear (year) {
      const startDate = dayjs(year).startOf('y').format('YYYY-MM-DD')
      const endDate = dayjs(year).endOf('y').format('YYYY-MM-DD')
      const query = { startDate, endDate }
      const data = await ProportionContactChannelsChennelService.findAll(query)
      for (let i = 0; i < data.data.length; i++) {
        this.labelsIntrgrate[i] = data.data[i].name
        this.dataIntegrate[i] = data.data[i].total
      }
      this.renderChart()
    },
    async getProportionContactChannelsChennelByQuarter (quarter) {
      if (Array.isArray(quarter) && quarter.length >= 2) {
        const startDate = quarter[0]
        const endDate = quarter[1]
        const query = { startDate, endDate }
        const data = await ProportionContactChannelsChennelService.findAll(query)
        for (let i = 0; i < data.data.length; i++) {
          this.labelsIntrgrate[i] = data.data[i].name
          this.dataIntegrate[i] = data.data[i].total
        }
        this.renderChart()
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
