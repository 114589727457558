<template>
  <div class="d-flex btn-filter">
    <div
      v-for="(btn, index) in btnFilter"
      :key="index">
      <b-button
        :variant="tabValue === index ? 'primary' : 'white'"
        pill
        class="h-100 px-3"
        :class="tabValue === index && 'grad--primary border-0'"
        @click="changeTab(index)">
        {{ btn.name }}
      </b-button>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    tab: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      currentTab: 0,
      btnFilter: [
        { name: 'กำหนดเอง', color: 'white' },
        { name: 'รายเดือน', color: 'white' },
        { name: 'รายปี', color: 'white' },
        { name: 'ไตรมาส', color: 'white' }
      ]
    }
  },
  computed: {
    tabValue: {
      get () {
        return this.tab
      },

      set (val) {
        this.$emit('update:tab', val)
      }
    }
  },
  created () {
    this.btnFilter[0].color = 'primary'
  },
  methods: {
    changeTab (index) {
      this.tabValue = index
      this.$emit('on-change-tab', index)
    }
  }
}
</script>

<style>
.btn-filter {
  border: 1px solid #00A8D0;
  border-radius: 27px;
  max-width: 700px;
}
.grad--btn {
  border: none;
  background-image: linear-gradient(88.07deg, #04A7D0 1.05%, #49D7FB 100%);
}
</style>
