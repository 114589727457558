<template>
  <div>
    <div class="d-flex justify-content-between pb-3">
      <div class="d-flex">
        <ButtonGroupFilter
          :tab.sync="tab"
          class="mr-2"
        />
        <DatePickerFilterRange
          v-if="tab === 0"
          v-model="dateCustom"
          @input="ProportionOfTerminationStatusAppealCustom($event)" />
        <DatePickerFilterMonth
          v-if="tab === 1"
          v-model="dateMonth"
          @input="ProportionOfTerminationStatusAppealMonth($event)" />
        <DatePickerFilterYear
          v-if="tab === 2"
          v-model="dateYear"
          @input="ProportionOfTerminationStatusAppealYear($event)" />
        <DatePickerFilterQuarter
          v-if="tab === 3"
          v-model="dateQuarter"
          @input="ProportionOfTerminationStatusAppealQuarter($event)" />
      </div>
    </div>
    <div
      v-show="tab === 0"
      class="d-flex justify-content-center"
    >
      <canvas
        ref="chartCanvas"
        width="420"
        height="420">
      </canvas>
    </div>
    <div v-if="tab === 1">
    </div>
    <div v-if="tab === 2">
    </div>
    <div v-if="tab === 3">
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js'
import ButtonGroupFilter from '@/components/ButtonGroupFilter.vue'
import DatePickerFilterRange from '@/components/DatePickerFilterRange.vue'
import DatePickerFilterMonth from '@/components/DatePickerFilterMonth.vue'
import DatePickerFilterYear from '@/components/DatePickerFilterYear.vue'
import DatePickerFilterQuarter from '@/components/DatePickerFilterQuarter.vue'
import ProportionOfTerminationStatusAppealProvider from '@/resources/ProportionOfTerminationStatusAppealProvider'
import dayjs from 'dayjs'

const ProportionOfTerminationStatusAppealService = new ProportionOfTerminationStatusAppealProvider()

export default {
  components: {
    ButtonGroupFilter,
    DatePickerFilterMonth,
    DatePickerFilterRange,
    DatePickerFilterYear,
    DatePickerFilterQuarter
  },
  data () {
    return {
      tab: 0,
      value: '',
      formatted: '',
      selected: '',
      dataIntegrate: [],
      labelsIntegrate: [],
      backgroundColorRandom: [
        '#EC6E85',
        '#6DBEBF',
        '#569FE5',
        '#F7CF6B'
      ],
      dateCustom: [],
      dateMonth: null,
      dateYear: null,
      dateQuarter: [],
      startDateFilter: null,
      endDateFilter: null
    }
  },
  created () {
    this.ProportionOfTerminationStatusAppealCustom(this.dateCustom)
    // this.renderChart()
  },
  methods: {
    renderChart () {
      const ctx = this.$refs.chartCanvas.getContext('2d')
      const dataOption = this.dataIntegrate
      const total = dataOption.reduce((sum, value) => sum + value, 0)
      const percentages = dataOption.map((value) => ((value / total) * 100).toFixed(0))
      const labelsOption = this.labelsIntegrate
      const pieChart = new Chart(ctx, {
        type: 'pie',
        data: {
          labels: labelsOption,
          datasets: [{
            label: 'My First Dataset',
            data: dataOption,
            backgroundColor: this.backgroundColorRandom,
            hoverOffset: 4
          }]
        },
        options: {
          responsive: false,
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                const dataset = data.datasets[tooltipItem.datasetIndex]
                const currentValue = dataset.data[tooltipItem.index]
                const percentage = percentages[tooltipItem.index]
                const label = labelsOption[tooltipItem.index]
                return `${label}: ${currentValue} (${percentage}%)`
              }
            }
          }
        }
      })
      console.log(pieChart)
    },
    onContext (val) {
      this.formatted = val.selectedFormatted
      this.selected = val.selectedYMD
    },
    async ProportionOfTerminationStatusAppealCustom (custom) {
      try {
        const startDate = dayjs(custom[0]).format('YYYY-MM-DD')
        const endDate = dayjs(custom[1]).format('YYYY-MM-DD')
        const query = { startDate, endDate }
        const data = await ProportionOfTerminationStatusAppealService.findAll(query)
        for (let i = 0; i < data.data.length; i++) {
          this.labelsIntegrate[i] = data.data[i].name
          this.dataIntegrate[i] = data.data[i].total
          // this.backgroundColorRandom[i] = this.dynamicColors()
        }
      } catch (error) {
        console.log(error)
      }
      this.renderChart()
    },
    async ProportionOfTerminationStatusAppealMonth (month) {
      try {
        const startDate = dayjs(month).startOf('M').format('YYYY-MM-DD')
        const endDate = dayjs(month).endOf('M').format('YYYY-MM-DD')
        const query = { startDate, endDate }
        const data = await ProportionOfTerminationStatusAppealService.findAll(query)
        for (let i = 0; i < data.data.length; i++) {
          this.labelsIntegrate[i] = data.data[i].name
          this.dataIntegrate[i] = data.data[i].total
          this.backgroundColorRandom[i] = this.dynamicColors()
        }
      } catch (error) {
        console.log(error)
      }
      this.renderChart()
    },
    async ProportionOfTerminationStatusAppealYear (year) {
      try {
        const startDate = dayjs(year).startOf('y').format('YYYY-MM-DD')
        const endDate = dayjs(year).endOf('y').format('YYYY-MM-DD')
        const query = { startDate, endDate }
        const data = await ProportionOfTerminationStatusAppealService.findAll(query)
        for (let i = 0; i < data.data.length; i++) {
          this.labelsIntegrate[i] = data.data[i].name
          this.dataIntegrate[i] = data.data[i].total
          this.backgroundColorRandom[i] = this.dynamicColors()
        }
      } catch (error) {
        console.log(error)
      }
      this.renderChart()
    },
    async ProportionOfTerminationStatusAppealQuarter (quarter) {
      try {
        if (Array.isArray(quarter) && quarter.length >= 2) {
          const startDate = quarter[0]
          const endDate = quarter[1]
          const query = { startDate, endDate }
          const data = await ProportionOfTerminationStatusAppealService.findAll(query)
          for (let i = 0; i < data.data.length; i++) {
            this.labelsIntegrate[i] = data.data[i].name
            this.dataIntegrate[i] = data.data[i].total
            this.backgroundColorRandom[i] = this.dynamicColors()
          }
        }
      } catch (error) {
        console.log(error)
      }
      this.renderChart()
    },
    dynamicColors () {
      const r = Math.floor(Math.random() * 255)
      const g = Math.floor(Math.random() * 255)
      const b = Math.floor(Math.random() * 255)
      return `rgb(${ r },${ g },${ b })`
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
