import { render, staticRenderFns } from "./ComplaintMonthlyReport.vue?vue&type=template&id=764bd54e&scoped=true&"
import script from "./ComplaintMonthlyReport.vue?vue&type=script&lang=js&"
export * from "./ComplaintMonthlyReport.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "764bd54e",
  null
  
)

export default component.exports