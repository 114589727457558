<template>
  <div>
    <b-row>
      <b-col
        cols="2">
      </b-col>
      <b-col
        cols="10">
        <b-card
          class="rounded-0 shadow-none">
          <p class="pt-2">
            วันนี้
          </p>
          <div
            v-for="(download, index) in fileDownload"
            :key="index">
            <b-row>
              <b-col
                cols="1">
                <img
                  :src="download.icon"
                  :alt="download.alt"
                  width="64"
                >
              </b-col>
              <b-col
                cols="1"
                md="auto">
                <div class="vl"></div>
              </b-col>
              <b-col cols="10">
                <b-link href="#foo">
                  {{ download.name }}
                </b-link>
                <p class="pt-1">
                  {{ download.time }}
                </p>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  components: {
  },
  data () {
    return {
      tabMenu: [
        { name: 'Dashboard' },
        { name: 'Report' },
        { name: 'Download' }
      ],
      fileDownload: [
        {
          icon: require('@/assets/images/icons/download-file-csv.svg'),
          name: 'Contact Center Monthly Report (RPT006).csv',
          time: '12:30 pm',
          alt: 'download-file-csv'
        },
        {
          icon: require('@/assets/images/icons/download-file-xlsx.svg'),
          name: 'Voice of customers Report (RPT012).xlsx',
          time: '12:30 pm',
          alt: 'download-file-xlsx'
        },
        {
          icon: require('@/assets/images/icons/download-file-xlsx.svg'),
          name: 'Contact Center Monthly Report (RPT006).xlsx',
          time: '10:00 am',
          alt: 'download-file-xlsx'
        },
        {
          icon: require('@/assets/images/icons/download-file-csv.svg'),
          name: 'รายงานคดี (กฎหมาย).csv',
          time: '09:30 am',
          alt: 'download-file-csv'
        },
        {
          icon: require('@/assets/images/icons/download-file-xlsx.svg'),
          name: 'Contact Center Monthly Report (RPT006).csv',
          time: '09:00 am',
          alt: 'download-file-xlsx'
        },
        {
          icon: require('@/assets/images/icons/download-file-csv.svg'),
          name: 'Contact Center Monthly Report (RPT006).csv',
          time: '08:00 pm',
          alt: 'download-file-csv'
        }
      ]
    }
  }

}
</script>

<style lang="scss" scoped>

.vl {
  border-left: 1px solid black;
  height: 60px;
}

</style>
